import React, { useContext, useEffect, useState } from "react";
import {
  Box,
  Typography,
  Button,
  IconButton,
  Menu,
  MenuItem,
} from "@mui/material";
import { setLabel } from "../../../store/labels/labels";
import { Trans, useTranslation } from "react-i18next";
import addButton from "./../../../assets/img/add-button.svg";
import addIcon from "./../../../assets/img/add-icon.svg";
import AdminIcon from "./../../../assets/img/admin.png";
import PropertyLogo from "./../../../assets/img/property-logo.png";
import "./sub-properties.scss";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import WithLandPageView from "../../../hoc/hoc-admin";
import { deleteSubProperty } from "../../../services/adminServices";
import {
  SellOutlined as SellOutlinedIcon,
  EmailOutlined as EmailOutlinedIcon,
  PhoneOutlined as PhoneOutlinedIcon,
  PlaceOutlined as PlaceOutlinedIcon,
  MoreVertOutlined as MoreVertOutlinedIcon,
} from "@mui/icons-material";
import {
  getManagementsByUserRoleId,
  getSubPropertiesByLocationBase,
  getSubPropertiesByPropertyManagementId,
  getUserDetails,
} from "../../../services/adminServices";
import ParentDetails from "../../../components/parent-details/parent-details";
import CustomDataTable from "../../../components/data-table/data-table";
import AppContext from "../../../context/app-context";
import { useDispatch } from "react-redux";
import { setValuesToSession } from "../../../utils/utils";

let titlel = "";
let subTitle = "";

const SubPropertiesList = () => {
  const { t }: { t: (key: string) => string } = useTranslation();
  const location = useLocation();
  const navigate = useNavigate();
  const { id } = useParams();
  const dispatch = useDispatch();
  const [companyCode, setCompanyCode] = useState<any>(null);
  const { setPropertyTypeDescription }: any = useContext(AppContext);
  const [deleteType, setDeleteType] = useState<"subProperty" | null>(null);
  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);
  const handleAddClick = () => {
    navigate("/admin/regional-property");
  };

  const navigateOption = (item?: any) => {
    console.log("Item received in navigateOption:", item);
    navigate(`/admin/add-unit-counts/${id}/${item.id}`);
    setValuesToSession(
      "propertyTypeDescription",
      item?.property_type_description,
    );
    setPropertyTypeDescription(item?.property_type_description);
  };
  const bodyListElements = (item: any) => {
    return (
      <>
        <td
          style={{ cursor: "pointer" }}
          onClick={() => {
            navigateOption(item);
          }}
        >
          {item.property_type_description}
        </td>
        <td
          onClick={() => {
            navigateOption(item);
          }}
        >
          {item.name}
        </td>
        <td
          onClick={() => {
            navigateOption(item);
          }}
        >
          {item.property_metrics[0]?.property_type_description === "unit"
            ? item.property_metrics[0]?.count
            : item.property_metrics[1]?.count}
        </td>
        <td
          onClick={() => {
            navigateOption(item);
          }}
        >
          {item.property_metrics?.length > 0
            ? (item.property_metrics[0]?.property_type_description === "floor"
                ? item.property_metrics[0]?.count
                : item.property_metrics[1]?.count) || "-"
            : "-"}
        </td>
        <td
          onClick={() => {
            navigateOption(item);
          }}
        >
          {"_"}
        </td>
        {/* <td>
          <img
            src={item.logo || AdminIcon}
            alt="Admin"
            className="admin-image"
          />
        </td>
        <td>-</td> */}
      </>
    );
  };
  const handleOpenDialog = () => {
    setOpenDialog(true);
  };
  const handleCloseDeleteDialog = () => {
    setDeleteDialogOpen(false);
  };

  const handleEditRegionalProperty = (companyCode: string, subId?: string) => {
    if (subId) {
      navigate(`/admin/add-sub-property/${id}/${subId}`);
    } else {
      console.log("No subId provided", companyCode);
      navigate(`/admin/add-sub-property/${id}/0`);
    }
  };

  const handleAddRegionalProperty = () => {
    navigate(`/admin/add-sub-property/${id}/0`);
  };

  const handleDeleteConfirmed = async (
    dataType: string,
    selectedSubProperty: any,
  ) => {
    if (dataType === "subProperty" && selectedSubProperty) {
      try {
        const subPropertyId = selectedSubProperty.id;

        const response = await deleteSubProperty(subPropertyId);

        console.log("Successfully deleted sub-property:", response);
      } catch (error) {
        console.error("Error deleting sub-property:", error);
      } finally {
        setDeleteDialogOpen(false);
        setDeleteType(null);
      }
    } else {
      console.warn("Invalid data type or no property selected for deletion");
    }
  };

  return (
    <Box className="properties-container">
      <ParentDetails
        dataFetch="property/getManagement/"
        parentCode={id}
        setCode={setCompanyCode}
        handleAddClick={handleAddClick}
        handleEditRout={`/admin/regional-property/${id}`}
        text="Property"
        type="property"
        category="sub"
      />

      <CustomDataTable
        headList={[
          t("propertyType"),
          t("propertyName"),
          t("noOfUnits"),
          t("noOfFloors"),
          t("assignee"),
        ]}
        bodyList={bodyListElements}
        dataFetch="property/getSubPropertiesByPropertyManagementId/"
        handleNavigate={handleAddRegionalProperty}
        handleEditSubRowRecord={handleEditRegionalProperty}
        title={t("listSubProperties")}
        buttonTitle={t("addSubProperty")}
        parentCode={id}
        type="subProperty"
        deleteType="subProperty"
        handleDeleteConfirmed={handleDeleteConfirmed}
        disabledPagination={true}
      />
    </Box>
  );
};

export default WithLandPageView(SubPropertiesList, {
  title: "Prime - Rajagiriya",
  subTitle: "Western Province",
});
function setOpenDialog(arg0: boolean) {
  throw new Error("Function not implemented.");
}
