import "./menu.scss";
import { Link, useLocation } from "react-router-dom";
import logoImage from "./../../assets/img/admin-logo.png";
import SpeedSharpIcon from "@mui/icons-material/SpeedSharp";
import ApartmentIcon from "@mui/icons-material/Apartment";
import PeopleOutlineIcon from "@mui/icons-material/PeopleOutline";
import { Trans, useTranslation } from "react-i18next";

interface IMenuProps {
  link: string;
}

export default function MainMenu({ link }: IMenuProps) {
  const { t }: { t: (key: string) => string } = useTranslation();
  return (
    <div className="left-menu">
      <div className="logo">
        <img src={logoImage} alt="Logo" />
      </div>
      <span className={link === "dashboard" ? "active" : ""}>
        <SpeedSharpIcon />
        <Link to={""} className={link === "dashboard" ? "active" : ""}>
          <Trans i18nKey="dashboard">Dashboard</Trans>
        </Link>
      </span>

      {link === "properties" && (
        <span className={link === "properties" ? "active" : ""}>
          <ApartmentIcon />
          <Link
            to={
              {
                pathname: "/admin/properties",
                state: { name: "Properties", state: "" },
              } as any
            }
            className={link === "properties" ? "active" : ""}
          >
            <Trans i18nKey="properties">Properties</Trans>
          </Link>
        </span>
      )}

      {link === "companies" && (
        <span className={link === "companies" ? "active" : ""}>
          <ApartmentIcon />
          <Link
            to={"/admin/companies"}
            className={link === "companies" ? "active" : ""}
          >
            <Trans i18nKey="companies">Companies</Trans>
          </Link>
        </span>
      )}

      <span className={link === "users" ? "active" : ""}>
        <PeopleOutlineIcon />
        <Link to={""} className={link === "user" ? "active" : ""}>
          <Trans i18nKey="users">Users</Trans>
        </Link>
      </span>
    </div>
  );
}
