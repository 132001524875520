import React from "react";
// import "./../../pages/login/login.scss";
import logoImage from "./../../assets/img/logo.png";
// import "./../../pages/signup/signup.scss";
import {
  Container,
  CssBaseline,
  Box,
  Avatar,
  Typography,
  TextField,
  Button,
  Grid,
  FormControlLabel,
  Checkbox,
  TypographyProps,
} from "@mui/material";
import "./textTitle.scss";
interface TextTitleProps extends TypographyProps {
  text: string;
  className?: string;
}

const TextTitle: React.FC<TextTitleProps> = ({ text, className, ...rest }) => {
  return (
    <Typography className={className} {...rest}>
      {text}
    </Typography>
  );
};

export default TextTitle;
