import { useState } from "react";
import { TextField } from "@mui/material";
import "./email-input.scss";
import { StringArraySupportOption } from "prettier";
interface IEmailTextProps {
  id: string;
  label: string;
  name: string;
  value: string;
  handleEvent?: (e: string) => void;
  autoFocus?: boolean;
  required?: boolean;
  fullWidth?: boolean;
  className?: string;
  error?: boolean;
  disabled?: boolean;
  helperText?: string;
  FormHelperTextProps?: React.ComponentProps<
    typeof TextField
  >["FormHelperTextProps"];
  onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
  onFocus?: any;
  onBlur?: any;
}

function EmailInput({
  id,
  label = "Email address",
  name = "email",
  value,
  handleEvent,
  autoFocus = false,
  required = false,
  fullWidth = true,
  className = "text-field1",
  disabled = false,
  FormHelperTextProps = {
    sx: { pt: 0, m: 0, px: 1 },
  },
  onChange,
  onFocus,
  onBlur,
  error,
  helperText,
}: IEmailTextProps) {
  // const [error, setError] = useState<string>("");

  // function validateEmail(email: string) {
  //   let re =
  //     /^[^\W_](?:[\w.-]*[^\W_])?@(?:\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.|(?:[\w-]+\.)+)(?:[a-zA-Z]{2,3}|[0-9]{1,3})\]?$/;
  //   return re.test(String(email).toLowerCase());
  // }

  // function handleEmailValidation(email: string) {

  //   if (required && email && !validateEmail(email)) {
  //     setError("please enter a valid email address");
  //   } else {
  //     setError("");
  //   }
  // }

  return (
    <TextField
      margin="normal"
      required={required}
      fullWidth={fullWidth}
      id={id}
      label={label}
      name={name}
      type="text"
      disabled={disabled}
      autoFocus={autoFocus}
      value={value}
      // onBlur={(e) => handleEmailValidation(e.target.value)}
      onChange={onChange}
      onFocus={onFocus}
      onBlur={onBlur}
      className={`${className} email-input-field`}
      aria-autocomplete="none"
      error={!!error}
      helperText={helperText}
      FormHelperTextProps={FormHelperTextProps}
      autoComplete="new-password"
      InputLabelProps={{
        className: "text-field-label1",
        classes: {
          asterisk: "hidden-asterisk",
        },
        sx: {
          "&.MuiInputLabel-shrink": {
            top: "1px",
            left: "0px",
          },
          // fontSize: '0.5rem',
        },
      }}
    />
  );
}

export default EmailInput;
