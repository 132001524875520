import instance from "../utils/axios";

// Fetch user details by email and user type
export const getUserDetails = async (email: string, userType: string) => {
  try {
    const response = await instance.get(`/user/getEmail`, {
      params: { email, user_type: userType },
    });
    return response.data;
  } catch (error) {
    throw error;
  }
};

// Create Property
export const createProperty = async (formData: FormData) => {
  try {
    const response = await instance.post(
      `/property/createManagement`,
      formData,
      {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      },
    );
    return response.data;
  } catch (error) {
    throw error;
  }
};

// get parent properties by user role id
export const getManagementsByUserRoleId = async (userRoleId: string) => {
  try {
    const response = await instance.get(
      `/property/getManagementsByUserRoleId/${userRoleId}`,
    );
    return response.data;
  } catch (error) {
    console.error("Error fetching management details:", error);
    throw error;
  }
};

export const getSubPropertiesByPropertyManagementId = async (
  userRoleId: string,
) => {
  try {
    const response = await instance.get(
      `/property/getSubPropertiesByPropertyManagementId/${userRoleId}`,
    );
    return response.data;
  } catch (error) {
    console.error("Error fetching management details:", error);
    throw error;
  }
};

export const getSubPropertiesByLocationBase = async (companyCode: string) => {
  try {
    const response = await instance.get(
      `/api/property/getSubPropertiesByPropertyManagementId/${companyCode}`,
    );
    return response.data;
  } catch (error) {
    console.error("Error fetching management details:", error);
    throw error;
  }
};

// update property
export const updateProperty = async (
  companyCode: string,
  formData: FormData,
) => {
  try {
    const response = await instance.put(
      `/property/editManagement/${companyCode}`,
      formData,
      {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      },
    );
    return response.data;
  } catch (error) {
    throw error;
  }
};

export function toTitleCase(word: string): string {
  if (!word) return "";
  return word.charAt(0).toUpperCase() + word.slice(1).toLowerCase();
}

export const getRegionalProperties = async (companyCode: string) => {
  try {
    const response = await instance.get(
      `/api/property/getRegionalProperties/${companyCode}`,
    );
    return response.data;
  } catch (error) {
    console.error("Error fetching management details:", error);
    throw error;
  }
};
export const deleteProperty = async (type: string, companyCode: string) => {
  try {
    const response = await instance.delete(
      `/api/property/delete/propertyDeletion/?type=${type}&company_code=${companyCode}`,
    );
    return response.data;
  } catch (error) {
    console.error("Error deleting property:", error);
    throw error;
  }
};
export const deleteCompanyAndServices = async (
  type: string,
  companyCode: string,
) => {
  try {
    const response = await instance.delete(
      `/api/property/delete/companyServices/?type=${type}&company_code=${companyCode}`,
    );
    return response.data;
  } catch (error) {
    console.error("Error deleting property:", error);
    throw error;
  }
};

export const addAmenity = async (data: {
  sub_property_id: string;
  amenityName: string;
  amenity_type_id: string;
}) => {
  try {
    const response = await instance.post(`/property/addAmenity`, data);
    return response.data;
  } catch (error: any) {
    console.error("Error adding amenity:", error.message);
    throw error;
  }
};

export const getAmenityByAmenityId = async (property_id: string) => {
  try {
    const response = await instance.get(
      `/property/getAmenityByAmenityId/${property_id}`,
    );
    return response.data;
  } catch (error) {
    console.error("Error fetching Amenity details:", error);
    throw error;
  }
};

export const editAmenity = async (
  propertyId: string,
  amenityName: string,
  amenityTypeId: string,
) => {
  try {
    const response = await instance.put(`/property/editAmenity/${propertyId}`, {
      amenityName,
      amenity_type_id: amenityTypeId,
    });
    console.log(response, "_____");
    return response.data;
  } catch (error) {
    console.error("Error fetching Amenity details:", error);
    throw error;
  }
};
export const getAllAmenitiesBySubPropertyId = async (
  sub_property_id: string,
) => {
  try {
    const response = await instance.get(
      `/property/getAllAmenitiesBySubPropertyId/${sub_property_id}`,
    );
    return response.data;
  } catch (error) {
    console.error("Error fetching Amenity details:", error);
    throw error;
  }
};

export const deleteAmenity = async (propertyId: string) => {
  try {
    const response = await instance.delete(
      `/api/property/deleteUnit/${propertyId}`,
    );
    return response.data;
  } catch (error) {
    console.error("Error deleting Amenity:", error);
    throw error;
  }
};
export const deleteSubProperty = async (subPropertyId: string) => {
  try {
    const response = await instance.delete(
      `/api/property/deleteSubProperty/${subPropertyId}`,
    );
    return response.data;
  } catch (error) {
    console.error("Error deleting SubProperty:", error);
    throw error;
  }
};
