import React, { useState, useEffect } from "react";
import { Box, TextField, Typography } from "@mui/material";
import {
  addAmenity,
  editAmenity,
  getAmenityByAmenityId,
} from "../../services/adminServices";
import SelectAmenity from "./selec-amenity";
import CancelSubmitButtons from "../AddNewSubProperty/components/CancelSubmitButtons";
import "./amenity-form.scss";

interface AddAmenityFormProps {
  open: boolean;
  onClose: () => void;
  initialData: any;

  onAmenityAdded?: (newAmenity: {
    amenityType: string;
    amenityName: string;
  }) => void;
  onAmenityUpdated?: (updatedAmenity: {
    amenityType: string;
    amenityName: string;
  }) => void;
  subPropertyId: string;
}

const AddAmenityForm: React.FC<AddAmenityFormProps> = ({
  open,
  onClose,
  initialData,
  onAmenityAdded,
  onAmenityUpdated,
  subPropertyId,
}) => {
  const [formData, setFormData] = useState({
    amenityType: "",
    amenityName: "",
    selectedTypeId: "",
    ...initialData,
  });
  const [error, setError] = useState<string | null>(null);
  const [success, setSuccess] = useState(false);
  const [loading, setLoading] = useState(false);
  const [errors, setErrors] = useState<{
    selectedType?: string;
    amenityName?: string;
  }>({});

  useEffect(() => {
    if (initialData) {
      setFormData({
        amenityType: initialData.propertyType || "",
        amenityName: initialData.name || "",
        selectedTypeId: initialData.property_type_id || "",
      });

      if (initialData.id) {
        getAmenityByAmenityId(initialData.id)
          .then((data: any) => {
            console.log(data);
          })
          .catch((error: any) => {
            console.error("Error fetching amenity details:", error);
          });
      }
    }
  }, [initialData]);

  const handleTypeChange = (value: {
    id: string;
    description: string;
    displayName: string;
  }) => {
    setFormData({
      ...formData,
      amenityType: value.id,
      selectedTypeId: value.id,
    });
    setErrors({ ...errors, selectedType: "" });
  };

  const handleSubmit = async (event: React.FormEvent) => {
    event.preventDefault();
    setError(null);
    setSuccess(false);
    setErrors({});

    const newErrors: { selectedType?: string; amenityName?: string } = {};

    if (!formData.amenityName) {
      newErrors.amenityName = "Amenity name is required.";
    }
    if (!formData.amenityType) {
      newErrors.selectedType = "Amenity type is required.";
    }

    if (Object.keys(newErrors).length > 0) {
      setErrors(newErrors);
      return;
    }

    const data = {
      sub_property_id: subPropertyId,
      amenityName: formData.amenityName,
      amenity_type_id: formData.selectedTypeId,
    };

    try {
      setLoading(true);
      if (initialData) {
        await editAmenity(
          initialData.id,
          formData.amenityName,
          formData.selectedTypeId,
        );
        if (onAmenityUpdated) {
          onAmenityUpdated({
            amenityType: formData.amenityType,
            amenityName: formData.amenityName,
          });
        }
      } else {
        await addAmenity(data);
        if (onAmenityAdded) {
          onAmenityAdded({
            amenityType: formData.amenityType,
            amenityName: formData.amenityName,
          });
        }
      }
      setSuccess(true);
      onClose();
    } catch (error) {
      setError("Failed to save amenity. Please try again.");
    } finally {
      setLoading(false);
    }
  };

  return (
    <Box className="amenity-form">
      <Box className="amenity-form__header">
        <Typography className="amenity-form__title">
          {initialData ? "Edit Amenity" : "Add New Amenity"}
        </Typography>
      </Box>

      <form className="amenity-form__content" onSubmit={handleSubmit}>
        <Typography className="amenity-form__label">
          Select an Amenity
        </Typography>
        <SelectAmenity
          value={formData.selectedTypeId}
          onChange={handleTypeChange}
          error={!!errors.selectedType}
          helperText={errors.selectedType}
          typeCategory="amenity"
        />

        <Box className="amenity-form__field">
          <Typography className="amenity-form__label">Amenity Name</Typography>
          <TextField
            fullWidth
            variant="outlined"
            value={formData.amenityName}
            error={!!errors.amenityName}
            helperText={errors.amenityName}
            onChange={(e) =>
              setFormData({ ...formData, amenityName: e.target.value })
            }
            placeholder="Amenity Name"
            className="amenity-form__input"
          />
        </Box>

        {error && (
          <Typography className="amenity-form__error">{error}</Typography>
        )}
        {success && (
          <Typography className="amenity-form__success">
            Amenity {initialData ? "updated" : "added"} successfully!
          </Typography>
        )}

        <CancelSubmitButtons
          handleCancel={onClose}
          handleSubmit={handleSubmit}
        />
      </form>
    </Box>
  );
};

export default AddAmenityForm;
