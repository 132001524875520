import React from "react";
import AdminHeader from "../components/admin-header/Admin-header";
import "./hoc-admin.scss";
import MainMenu from "../components/menu/menu";
import { useSelector, useDispatch } from "react-redux";
import { setLabel } from "../store/labels/labels";

const WithLandPageView = (Component: React.FC, labels: any) => {
  // const labels = useSelector((state: any) => state.labels); // Get the label from the Redux store
  // const dispatch = useDispatch();

  // const updateLabel = (data: any) => {
  //   dispatch(setLabel(data));
  // };

  return (props: any) => {
    return (
      <div className="admin-wrapper">
        <MainMenu {...props} />
        <main className="main">
          <section className="admin-hoc">
            {/* <header className="admin-heading">
              <h1 className="m-0">{labels.title}</h1>
              {labels.subTitle && <span> {labels.subTitle} </span>}
            </header> */}
            <AdminHeader />
          </section>
          <Component {...props} />
        </main>
      </div>
    );
  };
};

export default WithLandPageView;
