import {
  Box,
  Button,
  Divider,
  IconButton,
  Menu,
  MenuItem,
  Typography,
} from "@mui/material";
import { Trans, useTranslation } from "react-i18next";
import {
  SellOutlined as SellOutlinedIcon,
  EmailOutlined as EmailOutlinedIcon,
  PhoneOutlined as PhoneOutlinedIcon,
  PlaceOutlined as PlaceOutlinedIcon,
  MoreVertOutlined as MoreVertOutlinedIcon,
  DeleteOutlined as DeleteOutlinedIcon,
  EditOutlined as EditOutlinedIcon,
} from "@mui/icons-material";

import addButton from "./../../assets/img/add-button.svg";
import PropertyLogo from "./../../assets/img/property-logo.png";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import "./parent-details.scss";
import useFetch from "../../hooks/useFetch";
import instance from "../../utils/axios";
import DeleteModel from "../../pages/admin/deleteModel";
import CustomMenu from "./custom-menu-control";
import { setLabel } from "../../store/labels/labels";
import { useDispatch } from "react-redux";
import {
  deleteProperty,
  deleteCompanyAndServices,
} from "../../../src/services/adminServices";
import { AnyListenerPredicate } from "@reduxjs/toolkit";
import Company from "../../features/company/company";
const ParentDetails = ({
  dataFetch,
  parentCode,
  setCode,
  handleAddClick,
  handleEditRout,
  text,
  type,
  category,
}: any) => {
  const { t }: { t: (key: string) => string } = useTranslation();
  const navigate = useNavigate();
  const [parentAnchorEl, setParentAnchorEl] = useState<null | HTMLElement>(
    null,
  );
  const [selectedParentDetails, setSelectedParentDetails] = useState<any>(null);
  // const [regionalAnchorEls, setRegionalAnchorEls] = useState<Map<number, HTMLElement>>(new Map());
  const [deleteType, setDeleteType] = useState<
    "main" | "company" | "regional" | null
  >(null);
  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);
  const [parentDetails, setParentDetails] = useState<any>(null);
  const [selectedRegionalProperty, setSelectedRegionalProperty] =
    useState<any>(null);
  const [selectedParentCompany, setSelectedParentCompany] = useState<any>(null);
  const [selectedParentProperty, setSelectedParentProperty] =
    useState<any>(null);
  const [isDeleteData, setIsDeleteData] = useState(false);
  // You can set these in your `fetchParentDetails` function based on the API response

  // console.log('category', category, type);

  const dispatch = useDispatch();

  useEffect(() => {
    fetchParentDetails();
  }, [parentCode, isDeleteData]);

  const fetchParentDetails = async () => {
    const registType =
      type === "company" ? "companyRegistration" : "propertyRegistration";
    await instance
      .get(`${dataFetch}${parentCode}?registrationType=${registType}`)
      .then(({ status, data }) => {
        if (status === 200) {
          console.log("data", data);
          setParentDetails(data.data);
          setCode && setCode(data.data.company_code);
          category !== "primary" &&
            dispatch(
              setLabel({
                title: data.data.name,
                subTitle: data.data.state,
                isArrowNeed: true,
              } as any),
            );
        }
      })
      .catch((error: any) => {
        console.error("Error", error);
        setCode(null);
      });
  };

  const handleParentMenuClick = (
    event: React.MouseEvent<HTMLElement>,
    property: any,
  ) => {
    setParentAnchorEl(event.currentTarget);
    setSelectedParentDetails(property);
    setSelectedParentCompany(property);
  };

  const handleCloseMenu = () => {
    setParentAnchorEl(null);
    // setRegionalAnchorEls(new Map());
  };

  const handleEditParentProperty = () => {
    const routeMap: Record<string, { route: string; state: any }> = {
      "company-primary": {
        route: "/admin/parent-company",
        state: { companyDetails: selectedParentDetails },
      },
      "company-sub": {
        route: `/admin/branch-company/${parentCode}`,
        state: null,
      },
      "property-primary": {
        route: "/admin/parent-property",
        state: { propertyDetails: selectedParentDetails },
      },
      "property-sub": {
        route: `/admin/regional-property/${parentCode}`,
        state: null,
      },
      "property-amenity": {
        route: `/admin/regional-property/${parentCode}`,
        state: null,
      },
    };

    const key = `${type}-${category}` as keyof typeof routeMap;
    const { route, state } = routeMap[key];

    navigate(route, { state });
    handleCloseMenu();
  };

  const handleDeleteParentProperty = () => {
    setDeleteType(type === "company" ? "company" : "main");
    setDeleteDialogOpen(true);
    handleCloseMenu();
  };

  const handleCloseDeleteDialog = () => {
    setDeleteDialogOpen(false);
    setDeleteType(null);
  };

  const handleDeleteConfirmed = async (
    dataType: string,
    selectedParentCompany: any,
    selectedParentProperty: any,
  ) => {
    if (dataType === "company" && selectedParentCompany) {
      try {
        const response = await deleteCompanyAndServices(
          "primary",
          parentDetails?.company_code,
        );
        console.log("Successfully deleted main property:", response);
        setParentDetails(null);

        await fetchParentDetails();
      } catch (error) {
        console.error("Error during delete operation:", error);
      } finally {
        setDeleteDialogOpen(false);
        setDeleteType(null);
      }
    } else if (dataType === "main" && parentDetails?.company_code) {
      try {
        const response = await deleteProperty(
          "primary",
          parentDetails?.company_code,
        );
        console.log("Successfully deleted main property:", response);

        setParentDetails(null);

        // Optionally refetch the updated data
        await fetchParentDetails();
      } catch (error) {
        console.error("Error during delete operation:", error);
      } finally {
        setDeleteDialogOpen(false);
        setDeleteType(null);
      }
    }
  };

  return (
    <div className="parent-details">
      {parentDetails ? (
        <Box className="property-details-box">
          <img
            src={parentDetails.logo || PropertyLogo}
            alt="Property"
            className="property-logo"
          />
          <Box className="property-details-content">
            <Typography className="property-detail">
              <span className="property-detail-label">
                <SellOutlinedIcon /> {text} <Trans i18nKey="name">Name</Trans>
              </span>
              <span className="property-detail-value">
                {parentDetails.name}
              </span>
            </Typography>

            <Typography className="property-detail">
              <span className="property-detail-label">
                <EmailOutlinedIcon /> {text}{" "}
                <Trans i18nKey="email">Email</Trans>
              </span>
              <span className="property-detail-value">
                {parentDetails.email}
              </span>
            </Typography>

            <Typography className="property-detail">
              <span className="property-detail-label">
                <PhoneOutlinedIcon />{" "}
                <Trans i18nKey="contactNumber">Contact Number</Trans>
              </span>
              <span className="property-detail-value">
                {parentDetails.contact_no}
              </span>
            </Typography>

            <Typography className="property-detail">
              <span className="property-detail-label">
                <PlaceOutlinedIcon /> <Trans i18nKey="address">Address</Trans>
              </span>
              <span className="property-detail-value">
                {parentDetails.address}
              </span>
            </Typography>
            <span
              className="property-detail-icon"
              style={{ flexGrow: 1, textAlign: "end" }}
            >
              <IconButton
                onClick={(e) => handleParentMenuClick(e, parentDetails)}
                style={{ cursor: "pointer" }}
              >
                <MoreVertOutlinedIcon />
              </IconButton>

              <CustomMenu
                parentAnchorEl={parentAnchorEl}
                handleCloseMenu={handleCloseMenu}
                handleEdit={handleEditParentProperty}
                handleDelete={handleDeleteParentProperty}
              />
            </span>
          </Box>
        </Box>
      ) : (
        <Button
          variant="contained"
          className="add-property-button"
          startIcon={<img src={addButton} alt="Add" />}
          onClick={handleAddClick}
        >
          <Trans i18nKey="addParent">Add Parent</Trans> {text}{" "}
          <Trans i18nKey="detailsHere">Details Here</Trans>
        </Button>
      )}

      <DeleteModel
        open={deleteDialogOpen}
        onClose={handleCloseDeleteDialog}
        onDelete={() => {
          setIsDeleteData((prev) => !prev);
          handleDeleteConfirmed(
            deleteType ?? "",
            selectedParentCompany,
            selectedParentProperty,
          );
        }}
        deleteType={deleteType!}
      />
    </div>
  );
};

export default ParentDetails;
