import { Box } from "@mui/material";

const DoubleArrowIcon = ({ width = "11", height = "6" }) => {
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        width: "32px",
        lineHeight: 1,
        gap: 0.25,
      }}
    >
      <svg
        width={width}
        height={height}
        viewBox="0 0 11 6"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M5.80435 0.645L9.97088 4.7565C10.4626 5.24025 10.063 6 9.31671 6L0.983657 6C0.816645 6.00012 0.653131 5.95868 0.512697 5.88064C0.372264 5.8026 0.260863 5.69127 0.191835 5.55998C0.122808 5.42869 0.0990801 5.283 0.123494 5.14037C0.147907 4.99773 0.219427 4.8642 0.329489 4.75575L4.49601 0.64575C4.57756 0.565193 4.67812 0.500628 4.79095 0.45639C4.90378 0.412152 5.02626 0.389265 5.15018 0.389265C5.2741 0.389265 5.39659 0.412152 5.50942 0.45639C5.62225 0.500628 5.72281 0.565193 5.80435 0.64575V0.645Z"
          fill="black"
        />
      </svg>
      <svg
        width="11"
        height="6"
        viewBox="0 0 11 6"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M4.50034 5.605L0.333811 1.4935C-0.157901 1.00975 0.241723 0.25 0.98798 0.25H9.32103C9.48804 0.249877 9.65156 0.291316 9.79199 0.369356C9.93242 0.447396 10.0438 0.558728 10.1129 0.69002C10.1819 0.821312 10.2056 0.966999 10.1812 1.10963C10.1568 1.25227 10.0853 1.3858 9.9752 1.49425L5.80867 5.60425C5.72713 5.68481 5.62657 5.74937 5.51374 5.79361C5.40091 5.83785 5.27843 5.86074 5.1545 5.86074C5.03058 5.86074 4.9081 5.83785 4.79527 5.79361C4.68244 5.74937 4.58188 5.68481 4.50034 5.60425V5.605Z"
          fill="black"
        />
      </svg>
    </Box>
  );
};

export default DoubleArrowIcon;
