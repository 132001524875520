import { Box, Typography } from "@mui/material";
import React, { useCallback, useContext, useEffect, useState } from "react";
import CardMain from "../../../AddNewSubProperty/components/CardMain";
import TextField from "../text-field";
import { ReactComponent as AddIcon } from "../../../../pages/AddNewSubProperty/components/customIcons/AddIcon.svg";
import MinusIcon from "../../../AddNewSubProperty/components/customIcons/MinusIcon";
import { setLabel } from "../../../../store/labels/labels";
import { useDispatch } from "react-redux";
import WithLandPageView from "../../../../hoc/hoc-admin";
import CancelSubmitButtons from "../../../AddNewSubProperty/components/CancelSubmitButtons";
import { useNavigate, useParams } from "react-router-dom";
import "./AddNewUnit.scss";
import SelectOption from "../select-option";
import {
  createUnit,
  getAllUnitTypes,
  editUnit,
  getUnitDetails,
  getSubPropertiesBysubPropertyId,
} from "../../../../services/properties/properties";
import AlertMessage from "../../../../components/Aleart/AlertMEssage";
import AppContext from "../../../../context/app-context";

interface Props {}

interface FormState {
  numberOfUnits: string;
  numberOfFloors: string;
  unitTypeId: string;
}

interface ErrorState {
  numberOfUnits: string;
  numberOfFloors: string;
  unitType: string;
  tenantEmails: string[];
  tenantNames: string[];
}

const AddNewUnit: React.FC<Props> = ({}) => {
  const [additionalMembers, setAdditionalMembers] = useState<
    { id: string; memberName: string; memberEmail: string }[]
  >([{ id: "", memberName: "", memberEmail: "" }]);
  const [alertOpen, setAlertOpen] = useState(false);
  const [alertMessage, setAlertMessage] = useState("");
  const [alertSeverity, setAlertSeverity] = useState<
    "success" | "error" | "warning" | "info"
  >("success");
  const { propertyTypeDescription } = useContext(AppContext);
  const [removedTenantIds, setRemovedTenantIds] = useState<string[]>([]);

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { id, subId, propertyId } = useParams();

  const [formData, setFormData] = useState<FormState>({
    numberOfUnits: "",
    numberOfFloors: "",
    unitTypeId: "",
  });

  const [errors, setErrors] = useState<ErrorState>({
    numberOfUnits: "",
    numberOfFloors: "",
    unitType: "",
    tenantEmails: [],
    tenantNames: [],
  });

  useEffect(() => {
    dispatch(
      setLabel({
        title: "Add New Unit",
        subTitle:
          "Add a new property to your portfolio and start managing it efficiently.",
      } as any),
    );
  }, [dispatch]);

  useEffect(() => {
    if (propertyId && propertyId !== "0") {
      const fetchUnitDetailsForEdit = async () => {
        try {
          const response = await getUnitDetails(propertyId);
          if (response && response.data) {
            const unit = response.data;
            setFormData({
              numberOfUnits: unit.name,
              numberOfFloors: unit.floor_no || "",
              unitTypeId: unit.UnitTypeDetail?.id,
            });

            const tenants = unit.clients || [];
            setAdditionalMembers(
              tenants.length > 0
                ? tenants.map((tenant: any) => ({
                    id: tenant.id,
                    memberName: `${tenant.first_name} ${tenant.last_name}`,
                    memberEmail: tenant.email,
                  }))
                : [{ id: "", memberName: "", memberEmail: "" }],
            );
          }
        } catch (error) {
          console.error("Error fetching unit details for edit:", error);
        }
      };
      fetchUnitDetailsForEdit();
    } else {
      setFormData({ numberOfUnits: "", numberOfFloors: "", unitTypeId: "" });
      setAdditionalMembers([{ id: "", memberName: "", memberEmail: "" }]);
    }
  }, [propertyId]);

  const handleChange = (field: keyof FormState, value: string) => {
    if (field === "numberOfFloors" && isNaN(Number(value))) {
      return;
    }
    setFormData((prev) => ({ ...prev, [field]: value }));
    setErrors((prev) => ({
      ...prev,
      [field]: value
        ? ""
        : `Please enter ${field.replace(/([A-Z])/g, " $1").toLowerCase()}`,
    }));
  };

  const handleUnitTypeChange = (unitType: {
    id: string;
    displayName: string;
  }) => {
    setFormData((prev) => ({
      ...prev,
      unitTypeId: unitType.id,
    }));
    setErrors((prev) => ({
      ...prev,
      unitType: "",
    }));
  };

  const validateEmail = (email: string) => {
    const re = /^[a-zA-Z0-9._%+-]+@[a-zAZ0-9.-]+\.[a-zA-Z]{2,}$/;
    return re.test(email);
  };

  const handleMemberChange = (
    index: number,
    field: "memberName" | "memberEmail",
    value: string,
  ) => {
    const updatedMembers = [...additionalMembers];
    updatedMembers[index][field] = value;
    setAdditionalMembers(updatedMembers);
  };

  const handleMemberAdding = () => {
    setAdditionalMembers((prev) => [
      ...prev,
      { id: "", memberName: "", memberEmail: "" },
    ]);
    setErrors((prev) => ({
      ...prev,
      tenantEmails: [...prev.tenantEmails, ""],
    }));
  };

  const handleMemberRemoving = (index: number) => {
    if (additionalMembers.length === 1) {
      const removedTenant = additionalMembers[0];
      if (removedTenant.id) {
        setRemovedTenantIds((prev) => [...prev, removedTenant.id]);
      }

      setAdditionalMembers([
        { id: removedTenant.id, memberName: "", memberEmail: "" },
      ]);
      setErrors({
        numberOfUnits: "",
        numberOfFloors: "",
        unitType: "",
        tenantEmails: [""],
        tenantNames: [""],
      });
    } else {
      const removedTenant = additionalMembers[index];
      if (removedTenant.id) {
        setRemovedTenantIds((prev) => [...prev, removedTenant.id]);
      }

      setAdditionalMembers((prev) => prev.filter((_, i) => i !== index));
      setErrors((prev) => ({
        ...prev,
        tenantEmails: prev.tenantEmails.filter((_, i) => i !== index),
      }));
    }
  };
  const fetchSubPropertyOptions = useCallback(async () => {
    try {
      const response = await getAllUnitTypes(subId as string);
      return response.data.map((item: any) => ({
        id: item.id,
        displayName: item.name,
      }));
    } catch (error) {
      console.error("Error fetching unit types:", error);
      return [];
    }
  }, []);

  const getTenantName = (memberName: string) => {
    const parts = memberName.split(" ");
    const firstName = parts[0] || "";
    const lastName = parts.slice(1).join(" ") || "";
    return { first_name: firstName, last_name: lastName };
  };

  const validateUnitCount = async (subId: string) => {
    try {
      const subPropertyResponse = await getSubPropertiesBysubPropertyId(subId);
      if (subPropertyResponse?.data) {
        const subPropertyData = subPropertyResponse.data;
        if (
          subPropertyData?.registered_unit_count >=
          subPropertyData?.total_unit_count
        ) {
          return false;
        }
        return true;
      } else {
        return false;
      }
    } catch (error) {
      console.error("Error fetching sub property data", error);
      return false;
    }
  };

  const handleSubmit = async () => {
    // const isValidUnitCount = await validateUnitCount(subId as string);

    const isCreating = !propertyId || propertyId === "0";

    if (isCreating) {
      const isValidUnitCount = await validateUnitCount(subId as string);

      if (!isValidUnitCount) {
        setAlertMessage(
          "Unit registration cannot exceed the total available unit count.",
        );
        setAlertSeverity("warning");
        setAlertOpen(true);
        return;
      }
    }
    let isValid = true;
    const errorsCopy = { ...errors };
    if (!formData.numberOfUnits) {
      errorsCopy.numberOfUnits = "Unit Number is required";
      isValid = false;
    } else {
      errorsCopy.numberOfUnits = "";
    }
    if (!formData.unitTypeId) {
      errorsCopy.unitType = "Unit Type is required";
      isValid = false;
    } else {
      errorsCopy.unitType = "";
    }

    if (propertyTypeDescription === "Apartment" && !formData.numberOfFloors) {
      errorsCopy.numberOfFloors = "Floor Number is required";
      isValid = false;
    } else if (
      propertyTypeDescription === "Apartment" &&
      isNaN(Number(formData.numberOfFloors))
    ) {
      errorsCopy.numberOfFloors = "Floor Number must be a number";
      isValid = false;
    } else {
      errorsCopy.numberOfFloors = "";
    }

    const updatedTenantNamesErrors = [...errors.tenantNames];
    additionalMembers.forEach((member, index) => {
      const nameParts = member.memberName.trim().split(" ");
      if (member.memberName.trim()) {
        if (nameParts.length < 2) {
          updatedTenantNamesErrors[index] =
            "Please enter both first and last name";
          isValid = false;
        } else {
          updatedTenantNamesErrors[index] = "";
        }
      }
    });
    errorsCopy.tenantNames = updatedTenantNamesErrors;
    const updatedEmailsErrors = [...errors.tenantEmails];
    additionalMembers.forEach((member, index) => {
      if (member.memberEmail && !validateEmail(member.memberEmail)) {
        updatedEmailsErrors[index] = "Invalid email format";
        isValid = false;
      } else {
        updatedEmailsErrors[index] = "";
      }
    });

    errorsCopy.tenantEmails = updatedEmailsErrors;
    setErrors(errorsCopy);

    console.log("Validation Errors:", errorsCopy);

    if (!isValid) {
      console.log("Form has validation errors.");
      return;
    }

    const tenantInfo = additionalMembers.map((member, index) => {
      const { first_name, last_name } = getTenantName(member.memberName);
      return {
        first_name,
        last_name,
        email: member.memberEmail,
        id: member.id || undefined,
      };
    });
    const payload: any = {
      sub_property_id: subId,
      unit_no: formData.numberOfUnits,
      unit_type_id: formData.unitTypeId,
      tenant_infor: tenantInfo,
    };
    if (removedTenantIds.length > 0) {
      payload.tenant_infor = [
        ...payload.tenant_infor,
        ...removedTenantIds.map((id) => ({ id })),
      ];
    }

    if (propertyTypeDescription === "Apartment" && formData.numberOfFloors) {
      payload.floor_no = formData.numberOfFloors;
    }
    console.log("Payload to send:", payload);
    try {
      let response;
      if (!isCreating) {
        response = await editUnit(propertyId, payload);
      } else {
        response = await createUnit(payload);
      }
      const alertMessage = response?.message || response?.code;
      setAlertMessage(alertMessage);
      setAlertSeverity(response.success ? "success" : "error");
      setAlertOpen(true);

      if (response.success) {
        setTimeout(() => {
          navigate(`/admin/add-unit-counts/${id}/${subId}`);
        }, 3000);
      }
    } catch (error) {
      console.error("Error creating unit:", error);
    }
  };

  return (
    <>
      <Box sx={{ padding: "0px" }} className="card-main-container">
        <CardMain
          header="Unit Information"
          subHeader=""
          cardContent={
            <Box display={"flex"} gap={2} mb={2} className="input-container">
              <Box className="input-box" style={{ flex: 1 }}>
                <Typography className="input-label">Unit Number</Typography>
                <TextField
                  placeholder="Unit Number"
                  error={!!errors.numberOfUnits}
                  value={formData.numberOfUnits}
                  onChange={(e) =>
                    handleChange("numberOfUnits", e.target.value)
                  }
                  helperText={errors.numberOfUnits}
                />
              </Box>

              {propertyTypeDescription === "Apartment" && (
                <Box className="input-box" style={{ flex: 1 }}>
                  <Typography className="input-label">Floor Number</Typography>
                  <TextField
                    placeholder="Floor Number"
                    error={!!errors.numberOfFloors}
                    value={formData.numberOfFloors}
                    onChange={(e) =>
                      handleChange("numberOfFloors", e.target.value)
                    }
                    helperText={errors.numberOfFloors}
                  />
                </Box>
              )}

              <Box
                className="input-box"
                style={{
                  flex: propertyTypeDescription === "Apartment" ? 2 : 1,
                }}
              >
                <Typography className="input-label">Unit Type</Typography>
                <SelectOption
                  className="select-option-container"
                  value={formData.unitTypeId}
                  onChange={handleUnitTypeChange}
                  error={!!errors.unitType}
                  helperText={errors.unitType}
                  fetchOptions={fetchSubPropertyOptions}
                  placeholder="Select Unit Type"
                />
              </Box>
            </Box>
          }
        />

        <CardMain
          header="Tenant’s Information (Optional)"
          subHeader=""
          cardContent={
            <div className="tenant-info-container">
              {additionalMembers.map((member, index) => (
                <Box
                  display={"flex"}
                  gap={2}
                  mb={2}
                  key={index}
                  className="input-container"
                >
                  <Box className="input-box" position="relative">
                    <Typography className="input-label">Tenant Name</Typography>
                    <TextField
                      placeholder="Tenant Name"
                      value={member.memberName}
                      onChange={(e) =>
                        handleMemberChange(index, "memberName", e.target.value)
                      }
                      error={!!errors.tenantNames[index]}
                      helperText={errors.tenantNames[index]}
                    />
                    {index === additionalMembers.length - 1 && (
                      <div
                        className="add-icon-position"
                        onClick={handleMemberAdding}
                      >
                        <AddIcon />
                      </div>
                    )}
                  </Box>

                  <Box className="input-box" position="relative">
                    <Typography className="input-label">
                      Tenant Email
                    </Typography>
                    <TextField
                      placeholder="Tenant Email"
                      error={!!errors.tenantEmails[index]}
                      value={member.memberEmail}
                      onChange={(e) =>
                        handleMemberChange(index, "memberEmail", e.target.value)
                      }
                      helperText={errors.tenantEmails[index]}
                    />
                    {additionalMembers.length > 0 && (
                      <div
                        className="minus-icon-position"
                        onClick={() => handleMemberRemoving(index)}
                      >
                        <MinusIcon />
                      </div>
                    )}
                  </Box>
                </Box>
              ))}
            </div>
          }
        />

        <CancelSubmitButtons
          handleCancel={() => {
            navigate(`/admin/unit-list/${id}/${subId}`);
          }}
          handleSubmit={handleSubmit}
        />
        <AlertMessage
          open={alertOpen}
          onClose={() => setAlertOpen(false)}
          severity={alertSeverity}
          message={alertMessage}
        />
      </Box>
    </>
  );
};

export default WithLandPageView(AddNewUnit, {
  title: "Add New Unit",
  subTitle:
    "Add a new property to your portfolio and start managing it efficiently.",
});
