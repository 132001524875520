import React from "react";
import "./hoc-main.scss";
import Animation from "../components/Animation/animation";

const WithMainHoc = (Component: React.FC) => {
  return (props: any) => {
    return (
      <main className="main-wrapper">
        <Animation />
        <Component {...props} />
      </main>
    );
  };
};

export default WithMainHoc;
