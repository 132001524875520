import React from "react";
import { Button, ButtonProps } from "@mui/material";
import "./button.scss";

interface CustomButtonProps extends ButtonProps {
  label: string;
}

const CustomButton: React.FC<CustomButtonProps> = ({
  label,
  fullWidth = true,
  variant = "contained",
  className = "sign-in-button",
  onClick,
  ...rest
}) => {
  return (
    <Button
      fullWidth={fullWidth}
      variant={variant}
      className={className}
      onClick={onClick}
      {...rest}
    >
      {label}
    </Button>
  );
};

export default CustomButton;
