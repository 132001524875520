import React, { useCallback, useContext, useEffect, useState } from "react";
import { setLabel } from "../../store/labels/labels";
import { useDispatch } from "react-redux";
import {
  Box,
  Button,
  Dialog,
  Grid,
  Paper,
  TextField,
  Typography,
} from "@mui/material";
import { ReactComponent as EditIcon } from "../../assets/img/Svg/edit.svg";
import profilePic from "../../assets/img/profilePic.png";
import { Trans, useTranslation } from "react-i18next";
import "./MyProfile.scss";
import instance from "../../utils/axios";
import { useNavigate } from "react-router-dom";
import AppContext from "../../context/app-context";
import EditModal from "../admin/editModal";
import CustomTextField from "../../components/inputs/text-input";
import EmailInput from "../../components/inputs/email-input";

interface ProfileOpenProps {
  openProfile: boolean;
  handleClose: () => void;
}

const MyProfile: React.FC<ProfileOpenProps> = ({
  openProfile,
  handleClose,
}) => {
  const dispatch = useDispatch();
  const { t }: { t: (key: string) => string } = useTranslation();
  const [error, setError] = useState<string | null>(null);
  const navigate = useNavigate();
  const [loading, setLoading] = useState(true);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [email, setEmail] = useState("");

  const [profileData, setProfileData] = useState({
    first_name: "",
    last_name: "",
    email: "",
    company_name: "",
    phone_number: "",
  });

  const [errorMessages, setErrorMessages] = useState({
    first_name: "",
    last_name: "",
    email: "",
    company_name: "",
    phone_number: "",
  });

  const validateProfile = () => {
    const error: any = {};

    if (!profileData.first_name) error.first_name = t("firstNameRequired");
    if (!profileData.last_name) error.last_name = t("lastNameRequired");
    if (!profileData.email) error.email = t("emailRequired");

    if (!profileData.company_name)
      error.company_name = t("companyNameRequired");
    if (!profileData.phone_number) {
      error.phone_number = t("PhoneNumberRequired");
    } else if (!/^\d{10}$/.test(profileData.phone_number)) {
      error.phone_number = t("enterTenDigitNumber");
    }

    setErrorMessages(error);

    return Object.keys(error).length === 0;
  };

  const { user } = useContext(AppContext);

  useEffect(() => {
    const fetchProfileData = async () => {
      try {
        const response = await instance.get(
          `/user/getSuperAdminProfiles/${user.userRoleId}`,
        );
        setProfileData(response.data.data); // Ensure all fields are set
        setLoading(false);
      } catch (err) {
        setError("Failed to fetch profile data");
        setLoading(false);
      }
    };
    fetchProfileData();
  }, [user.userRoleId]);

  const handleInputChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      e.stopPropagation(); // Prevent click event from propagating to Dialog
      const { name, value } = e.target;
      setProfileData((prevData) => ({ ...prevData, [name]: value }));
    },
    [],
  );

  const SaveDataChanges = async () => {
    const isValid = validateProfile();

    if (!isValid) {
      return;
    }
    try {
      const payload = {
        first_name: profileData.first_name,
        last_name: profileData.last_name,
        email: profileData.email,
        company_name: profileData.company_name,
        phone_number: profileData.phone_number,
      };
      const response = await instance.put(
        `/user/editSuperAdminProfile/${user.userRoleId}`,
        payload,
      );
      if (response.status === 200) {
        navigate("/admin/properties");
        handleClose();
        CloseConfirmModel(); // Close the dialog after successful save
      }
    } catch (err) {
      setError("Failed to update profile data");
    }
  };

  const OpenConfirmModel = () => {
    setIsModalOpen(true);
  };

  const CloseConfirmModel = () => {
    setIsModalOpen(false);
  };

  const onKeyDown = (e: React.KeyboardEvent) => {
    e.stopPropagation();
  };

  return (
    <Dialog
      open={openProfile}
      onClose={(e, reason) => {
        if (reason === "backdropClick") {
          return; // Prevent closing on backdrop click
        }
        handleClose(); // Handle normal close logic
      }}
      BackdropProps={{
        onClick: (e) => e.stopPropagation(), // Prevent backdrop click from closing the dialog
      }}
      className="main-profile-container custom-dialog"
    >
      <div
        className="profile-container"
        onClick={(e) => e.stopPropagation()} // Stop event propagation when clicking inside dialog
      >
        <Typography className="top-header">
          <Trans i18nKey="myProfile">My Profile</Trans>
        </Typography>

        <Paper elevation={1} className="profile-picture">
          <img src={profilePic} alt="Profile" className="profile-img" />
          <EditIcon className="edit-icon" />
        </Paper>

        <Box
          className="container"
          sx={{ width: "525px", height: "261px", marginLeft: "27.5px" }}
        >
          <Grid container item spacing={2}>
            {/* First Name */}
            <Grid item xs={6}>
              <Typography className="text">
                <Trans i18nKey="firstName">First Name</Trans>
              </Typography>
              <CustomTextField
                className="text-field"
                label=""
                id="firstName"
                name="first_name"
                onChange={handleInputChange}
                value={profileData.first_name}
                error={Boolean(errorMessages.first_name)}
                helperText={errorMessages.first_name}
                onKeyDown={onKeyDown}
              />
            </Grid>

            {/* Last Name */}
            <Grid item xs={6}>
              <Typography className="text">
                <Trans i18nKey="lastName">Last Name</Trans>
              </Typography>
              <CustomTextField
                className="text-field"
                label=""
                id="lastName"
                name="last_name"
                onChange={handleInputChange}
                value={profileData.last_name}
                error={Boolean(errorMessages.last_name)}
                helperText={errorMessages.last_name}
                onKeyDown={onKeyDown}
              />
            </Grid>

            {/* Email */}
            <Grid item xs={12}>
              <Typography className="text">
                <Trans i18nKey="email">Email</Trans>
              </Typography>
              <EmailInput
                id="email"
                disabled={true}
                label=""
                name="email"
                value={profileData.email}
                onChange={handleInputChange}
                error={Boolean(errorMessages.last_name)}
                helperText={errorMessages.last_name}
              />
            </Grid>

            {/* Company Name */}
            <Grid item xs={6}>
              <Typography className="text">
                <Trans i18nKey="companyName">Company Name</Trans>
              </Typography>
              <CustomTextField
                className="text-field"
                label=""
                id="companyName"
                name="company_name"
                onChange={handleInputChange}
                value={profileData.company_name}
                error={Boolean(errorMessages.company_name)}
                helperText={errorMessages.company_name}
                onKeyDown={onKeyDown}
              />
            </Grid>

            {/* Phone Number */}
            <Grid item xs={6}>
              <Typography className="text">
                <Trans i18nKey="contactNumber">Contact Number</Trans>
              </Typography>
              <CustomTextField
                className="text-field"
                label=""
                id="phone_number"
                name="phone_number"
                onChange={handleInputChange}
                value={profileData.phone_number}
                error={Boolean(errorMessages.phone_number)}
                helperText={errorMessages.phone_number}
                onKeyDown={onKeyDown}
              />
            </Grid>
          </Grid>
        </Box>

        <Box className="buttons-container">
          <Button
            variant="contained"
            className="cancel-button"
            onClick={handleClose}
          >
            <Trans i18nKey="cancel">Cancel</Trans>
          </Button>
          <Button
            variant="contained"
            className="submit-button"
            onClick={OpenConfirmModel}
          >
            <Trans i18nKey="submit">Submit</Trans>
          </Button>
          <EditModal
            open={isModalOpen}
            onClose={CloseConfirmModel}
            onEdit={SaveDataChanges}
            editType="main"
          />
        </Box>
      </div>
    </Dialog>
  );
};

export default MyProfile;
