import instance from "../../utils/axios";

// get parent properties by user role id
export const getOneParentPropertiesByUserRoleId = async (
  userRoleId: string,
  registrationType: string,
) => {
  try {
    const response = await instance.get(
      `/property/getManagementsByUserRoleId/${userRoleId}`,
      {
        params: {
          registrationType,
        },
      },
    );
    return response;
  } catch (error) {
    console.error("Error fetching management details:", error);
    throw error;
  }
};

// get parent properties by user role id
export const getOneRegionalProperties = async (companyCode: string) => {
  try {
    const response = await instance.get(
      `/property/getRegionalProperties/${companyCode}`,
    );
    return response;
  } catch (error) {
    console.error("Error fetching management details:", error);
    throw error;
  }
};

export const getManagementByCompanyCode = async (companyCode: string) => {
  try {
    const response = await instance.get(
      `/property/getManagement/${companyCode}`,
    );
    return response.data;
  } catch (error) {
    console.error("Error fetching management details:", error);
    throw error;
  }
};

export const getPropertyTypesByCategory = async (typeCategory: string) => {
  try {
    const response = await instance.get(
      `/property/types/getPropertyTypeByCategory?type_category=${typeCategory}`,
    );
    return response.data;
  } catch (error) {
    console.error("Error fetching property types:", error);
    throw error;
  }
};

export const createManagementUnit = async (payload: any) => {
  try {
    const response = await instance.post(
      `/property/createManagementUnit`,
      payload,
    );
    return response.data;
  } catch (error) {
    console.error("Error creating management unit:", error);
    throw error;
  }
};

export const createUnit = async (payload: any) => {
  try {
    const response = await instance.post(`/property/createUnit`, payload);
    return response.data;
  } catch (error) {
    console.error("Error creating management unit:", error);
    throw error;
  }
};

export const getAllUnitTypes = async (subPropertyId: string) => {
  try {
    const response = await instance.get(
      `/property/getAllUnitTypes/${subPropertyId}`,
    );
    return response.data;
  } catch (error) {
    console.error("Error fetching unit type details:", error);
    throw error;
  }
};

export const getUnitDetails = async (propertyId: string) => {
  try {
    const response = await instance.get(
      `/property/getUnitDetails/${propertyId}`,
    );
    return response.data;
  } catch (error) {
    console.error("Error fetching unit type details:", error);
    throw error;
  }
};

export const getAllUnitsBySubPropertyId = async (subId: string) => {
  try {
    const response = await instance.get(
      `/property/getAllUnitsBySubPropertyId/${subId}`,
    );
    return response.data;
  } catch (error) {
    console.error("Error fetching unit type details:", error);
    throw error;
  }
};

export const editUnit = async (propertyId: string, payload: any) => {
  try {
    const response = await instance.put(
      `/property/editUnit/${propertyId}`,
      payload,
    );
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const deleteUnit = async (propertyId: string) => {
  try {
    const response = await instance.delete(
      `/api/property/deleteUnit/${propertyId}`,
    );
    return response.data;
  } catch (error) {
    console.error("Error deleting unit:", error);
    throw error;
  }
};

export const getSubPropertiesBysubPropertyId = async (subId: string) => {
  try {
    const response = await instance.get(
      `/property/getSubPropertiesBysubPropertyId/${subId}`,
    );
    return response.data;
  } catch (error) {
    console.error("Error fetching unit type details:", error);
    throw error;
  }
};
