import React from "react";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Button,
  Typography,
  IconButton,
} from "@mui/material";
import { Trans, useTranslation } from "react-i18next";
import { DeleteOutlined as DeleteOutlinedIcon } from "@mui/icons-material";
import "./editModal.scss";
import EditIconCircle from "../AddNewSubProperty/components/customIcons/EditIconCircle";

interface EditModalProps {
  open: boolean;
  onClose: () => void;
  onEdit: () => void;
  editType: "main" | "regional" | "service" | "company" | "branch";
}

const EditModal: React.FC<EditModalProps> = ({
  open,
  onClose,
  onEdit,
  editType,
}) => {
  const { t }: { t: (key: string) => string } = useTranslation();
  const getDialogText = () => {
    if (editType === "main") {
      return (
        <Typography
          fontSize={"16px"}
          mt={"10px"}
          mb={"31px"}
          p={"0px"}
          variant="body2"
          color="textSecondary"
          className="delete-message"
        >
          <Trans i18nKey="saveChangesMadeThisForm">
            Are you sure you want to save the changes made to this form.
          </Trans>
        </Typography>
      );
    } else if (editType === "regional") {
      return (
        <Typography
          variant="body2"
          color="textSecondary"
          className="delete-message"
        >
          <Trans i18nKey="deleteThisLocationBasedProperty">
            Are you sure you want to delete this location-based property? This
            will permanently remove all associated sub-properties, amenities,
            and data linked to this location-based property. This action cannot
            be undone. Please confirm to proceed.
          </Trans>
        </Typography>
      );
    } else if (editType === "company") {
      return (
        <Typography
          variant="body2"
          color="textSecondary"
          className="delete-message"
        >
          <Trans i18nKey="deleteThisCompany">
            Are you sure you want to delete this Company? Deleting will remove
            all associated branches, services and related data. This action
            cannot be undone. Please confirm to proceed.
          </Trans>
        </Typography>
      );
    } else if (editType === "branch") {
      return (
        <Typography
          variant="body2"
          color="textSecondary"
          className="delete-message"
        >
          <Trans i18nKey="deleteThisBranch">
            Are you sure you want to delete this branch? Deleting will remove
            all associated services and related data. This action cannot be
            undone. Please confirm to proceed.
          </Trans>
        </Typography>
      );
    } else if (editType === "service") {
      return (
        <Typography
          variant="body2"
          color="textSecondary"
          className="delete-message"
        >
          <Trans i18nKey="deleteThisService">
            Are you sure you want to delete this service? Deleting will remove
            all related data. This action cannot be undone. Please confirm to
            proceed.
          </Trans>
        </Typography>
      );
    }
  };

  return (
    <div className="">
      <Dialog
        open={open}
        onClose={onClose}
        aria-labelledby="delete-confirmation-dialog"
        maxWidth={false}
        fullWidth={false}
        className="model-container edit-modal"
        // sx={{
        //   "& .MuiPaper-root-MuiDialog-paper": {},
        //   "& .MuiDialogContent-root": {
        //     padding: "0px",
        //   },
      >
        <DialogTitle className="header">
          <EditIconCircle />
        </DialogTitle>

        <DialogContent className="body-container">
          <Typography
            variant="h6"
            align="center"
            sx={{
              marginBottom: "16px",
              fontWeight: 600,
              fontSize: "22px",
              mt: "31px",
              mb: "0px",
            }}
          >
            <Trans i18nKey="editConfirmation">Edit Confirmation</Trans>
          </Typography>

          {getDialogText()}
        </DialogContent>

        <DialogActions
          // sx={{ justifyContent: "space-between", padding: "16px" }}
          className="dialog-action-wrap"
        >
          <Button
            className="cancel-button"
            variant="outlined"
            onClick={onClose}
            // sx={{
            //   borderRadius: "7px",
            //   fontSize: "16px",
            // }}
          >
            <Trans i18nKey="cancel"> Cancel</Trans>
          </Button>

          <Button
            className="edit-button"
            variant="contained"
            onClick={onEdit}
            // sx={{
            //   borderRadius: "7px",
            //   fontSize: "16px",
            //   // padding: "opx",
            // }}
          >
            <Trans i18nKey="saveChanges">Save Changes</Trans>
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default EditModal;
