import { lazy, Suspense, useContext, useEffect } from "react";
import { Routes, Route, useNavigate, useLocation } from "react-router-dom";
import AppContext from "./context/app-context";
import { Bars } from "react-loading-icons";
import "./utils/i18n";
import AccountVerify from "./pages/account-verification/account-verification";
import SubProperties from "./pages/admin/sub-properties/sub-properties";
import NameCollection from "./pages/name-collection/name-collection";
import AddingAminityInformation from "./pages/AddNewSubProperty/AddingAminityInformation";
import AddNewUnit from "./pages/admin/properties/unit-registration/AddNewUnit";
import UnitCounts from "./components/UnitsCounts/UnitCounts";
import AminityRegisration from "./pages/aminity/aminity-regisration";
import AddAmenityForm from "./pages/aminity/aminety-form";

const Home = lazy(() => import("./pages/Home"));
const Login = lazy(() => import("./pages/login/login"));
const Signup = lazy(() => import("./pages/signup/signup"));
const ForgetPassword = lazy(
  () => import("./pages/forget-password/forget-password"),
);
const NewPassword = lazy(
  () => import("./pages/forget-password/set-new-password"),
);
const Admin = lazy(() => import("./pages/admin/properties/properties"));

const ParentProperty = lazy(
  () => import("./pages/admin/properties/parent-property"),
);
const RegionProperty = lazy(
  () => import("./pages/admin/properties/regional-property/regional-property"),
);
const Company = lazy(() => import("./pages/admin/company/companies"));
const ParentCompany = lazy(
  () => import("./pages/admin/company/parent-company"),
);
const BranchCompany = lazy(
  () => import("./pages/admin/company/branch-company"),
);
const AdminProfileCreation = lazy(
  () => import("./pages/AdminprofileCreation/AdminprofileCreation"),
);
// const AddNewSubProperty = lazy(()=> import("./pages/AddNewSubProperty/AddingNewSubProperty"));
// const AddingAminityInformation = lazy(()=> import("./pages/AddNewSubProperty/AddingAminityInformation"))
// const AddingNewSubProperty = lazy(()=> import("./pages/AddNewSubProperty/AddNewSubProperty"))
const AddingNewSubProperty = lazy(
  () => import("./pages/admin/properties/sub-properties/add-new-sub-property"),
);
const Verify = lazy(() => import("./pages/verify/verify"));
const Users = lazy(() => import("./pages/users/users"));
const Dashboard = lazy(() => import("./pages/dashboard/dashboard"));
const SelectServiceType = lazy(
  () => import("./pages/admin/company/services/select-service-type"),
);
const ServiceList = lazy(
  () => import("./pages/admin/company/services/service-list"),
);

const UnitList = lazy(
  () => import("./pages/admin/properties/unit-registration/unit-list"),
);

function App() {
  // set the token for this
  const { token }: any = useContext(AppContext);
  const navigate = useNavigate();
  const { pathname } = useLocation();

  // useEffect(() => {
  //   if (
  //     pathname !== '/verifyUser' &&
  //     pathname !== '/forget-password' &&
  //     pathname !== '/reset-password'
  //   ) {
  //     if (!token) navigate('/');
  //   }
  // }, [token, pathname]);

  return (
    <div className="App">
      <Suspense
        fallback={
          <div className="loading">
            <Bars />{" "}
          </div>
        }
      >
        <Routes>
          <Route path="/" element={<Login />} />
          <Route path="/verifyUser" element={<Verify />} />
          <Route path="/login" element={<Login />} />
          <Route path="/signup" element={<Signup />} />
          <Route path="/name-collection" element={<NameCollection />} />
          <Route path="/forget-password" element={<ForgetPassword />} />
          <Route path="/reset-password" element={<NewPassword />} />
          <Route
            path="/admin/dashboard"
            element={<Dashboard link="dashboard" />}
          />
          <Route path="/admin/users" element={<Users link="users" />} />
          <Route
            path="/admin/properties"
            element={<Admin link="properties" />}
          />
          <Route
            path="/admin/parent-property"
            element={<ParentProperty link="properties" />}
          />
          <Route
            path="/admin/regional-property/"
            element={<RegionProperty link="properties" />}
          />
          <Route
            path="/admin/regional-property/:companyCode"
            element={<RegionProperty link="properties" />}
          />
          <Route
            path="/admin/sub-properties-list/:id"
            element={<SubProperties link="properties" />}
          />
          <Route
            path="/admin/add-sub-property/:id"
            element={<AddingNewSubProperty link="properties" />}
          />
          <Route
            path="/admin/add-sub-property/:id/:subId"
            element={<AddingNewSubProperty link="properties" />}
          />
          <Route
            path="/admin/add-unit-counts/:id/:subId"
            element={<UnitCounts link="properties" />}
          />
          {/* <Route path="/admin/add-new-sub-property" element={<AddingNewSubProperty/>} /> */}
          <Route
            path="/admin/admin-profile-creation"
            element={<AdminProfileCreation />}
          />
          <Route path="/account-verification" element={<AccountVerify />} />
          <Route
            path="/admin/companies"
            element={<Company link="companies" />}
          />
          <Route
            path="/admin/parent-company"
            element={<ParentCompany link="companies" />}
          />
          <Route
            path="/admin/branch-company"
            element={<BranchCompany link="companies" />}
          />
          <Route
            path="/admin/branch-company/:companyCode"
            element={<BranchCompany link="companies" />}
          />
          <Route
            path="/admin/sub-property-add"
            element={<AddingAminityInformation link="properties" />}
          />
          <Route
            path="/admin/select-service-type/:company_code"
            element={<SelectServiceType link="companies" />}
          />
          <Route
            path="/admin/select-service-type/:main_skill/:company_code"
            element={<SelectServiceType link="companies" />}
          />
          <Route
            path="/admin/list-of-service/:company_code"
            element={<ServiceList link="companies" />}
          />
          <Route
            path="/admin/add-new-unit/:id/:subId/:propertyId"
            element={<AddNewUnit link="properties" />}
          />
          <Route
            path="/admin/unit-list/:id/:subId"
            element={<UnitList link="properties" />}
          />

          <Route
            path="/admin/aminity-regisration"
            element={<AminityRegisration link="properties" />}
          />
          <Route
            path="/admin/aminity-regisration/:company_code/:subId"
            element={<AminityRegisration link="properties" />}
          />

          <Route path="*" element={<Home />} />
        </Routes>
      </Suspense>
    </div>
  );
}

export default App;
