import { configureStore } from "@reduxjs/toolkit";
import homeReducer from "./home/home";
import labelReducer from "./labels/labels";

const store = configureStore({
  reducer: {
    home: homeReducer,
    labels: labelReducer,
  },
});

export default store;
