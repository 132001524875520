import { Box, Button } from "@mui/material";
import React from "react";
import { Trans } from "react-i18next";
interface CancelSubmitButtonsProps {
  handleCancel: React.MouseEventHandler<HTMLButtonElement>;
  handleSubmit: React.MouseEventHandler<HTMLButtonElement>;
}
const CancelSubmitButtons: React.FC<CancelSubmitButtonsProps> = ({
  handleCancel,
  handleSubmit,
}) => {
  const mainStyle: React.CSSProperties = {
    fontFamily: '"Inter", sans-serif',
    fontWeight: "400",
    fontSize: "16px",
    width: "95px",
    height: "39px",
    borderRadius: "10px",
  };
  return (
    <Box sx={{ display: "flex", justifyContent: "end", mt: 2, gap: 2 }}>
      <Button
        variant="contained"
        onClick={handleCancel}
        sx={{
          backgroundColor: "#C23434",
          textAlign: "center",
          lineHeight: "normal",
          textTransform: "none",
          ...mainStyle,
        }}
        size="small"
      >
        <Trans i18nKey="cancel">Cancel</Trans>
      </Button>
      <Button
        variant="contained"
        sx={{
          backgroundColor: "#059B9A",
          textAlign: "center",
          lineHeight: "normal",
          textTransform: "none",
          ...mainStyle,
        }}
        size="small"
        onClick={handleSubmit}
      >
        <Trans i18nKey="submit">Submit</Trans>
      </Button>
    </Box>
  );
};

export default CancelSubmitButtons;
