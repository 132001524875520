import React, { useEffect, useState, useRef, useCallback } from "react";
import { Box, MenuItem, CircularProgress } from "@mui/material";
import SingleFormControl from "../../AddNewSubProperty/components/SingleFormControl";

interface SelectOptionProps {
  value: string;
  onChange: (value: {
    id: string;
    description: string;
    displayName: string;
  }) => void;
  error?: boolean;
  helperText?: string;
  fetchOptions?: () => Promise<
    { id: string; description: string; displayName: string }[]
  >;
  placeholder: string;
  className?: string;
}

const SelectOption: React.FC<SelectOptionProps> = ({
  value,
  onChange,
  error = false,
  helperText = "",
  fetchOptions,
  placeholder,
  className,
}) => {
  const [options, setOptions] = useState<
    { id: string; description: string; displayName: string }[]
  >([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState<string>("");
  const [selectedValue, setSelectedValue] = useState<string>(value);

  const optionsRef = useRef<
    { id: string; description: string; displayName: string }[]
  >([]);
  const loadingRef = useRef<boolean>(false);

  const loadOptions = useCallback(async () => {
    if (optionsRef.current.length > 0) {
      setOptions(optionsRef.current);
      const matchedOption = optionsRef.current.find(
        (option) => option.id === value,
      );
      if (matchedOption) {
        setSelectedValue(matchedOption.displayName);
      } else {
        setSelectedValue("");
      }
      return;
    }
    if (!fetchOptions) return;

    setLoading(true);
    setErrorMessage("");

    try {
      const response = await fetchOptions();
      optionsRef.current = response;
      setOptions(response);
      const matchedOption = response.find((option) => option.id === value);
      if (matchedOption) {
        setSelectedValue(matchedOption.displayName);
      } else {
        setSelectedValue("");
      }
    } catch (err) {
      setErrorMessage("Failed to load options.");
      console.error("Error fetching options:", err);
    } finally {
      setLoading(false);
    }
  }, [fetchOptions, value]);

  useEffect(() => {
    loadOptions();
  }, [loadOptions]);

  const renderMenuItems = () => {
    if (loading) {
      return (
        <MenuItem disabled>
          <CircularProgress size={24} />
        </MenuItem>
      );
    }

    if (errorMessage) {
      return <MenuItem disabled>{errorMessage}</MenuItem>;
    }

    if (options.length === 0) {
      return <MenuItem disabled>No options available</MenuItem>;
    }

    return options.map((option) => (
      <MenuItem key={option.id} value={option.displayName}>
        {option.displayName}
      </MenuItem>
    ));
  };

  const handleChange = (e: any) => {
    const selectedOption = options.find(
      (option) => option.displayName === e.target.value,
    );
    if (selectedOption) {
      onChange({
        id: selectedOption.id,
        description: selectedOption.description,
        displayName: selectedOption.displayName,
      });
      setSelectedValue(selectedOption.displayName);
    }
  };

  return (
    <Box sx={{ display: "flex", alignItems: "center", mt: 0 }}>
      <SingleFormControl
        value={selectedValue}
        onChange={handleChange}
        error={error}
        helperText={helperText}
        disabled={loading}
        className={className}
      >
        <MenuItem disabled value="">
          <span style={{ color: "#6b6b6b" }}>
            {loading ? "Loading..." : placeholder}
          </span>
        </MenuItem>
        {renderMenuItems()}
      </SingleFormControl>
    </Box>
  );
};

export default SelectOption;
