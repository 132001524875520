import instance from "../../../utils/axios";

export interface PropertyInfo {
  id: string;
  description: string;
}

export interface TransformedPropertyInfo {
  id: string;
  description: string;
  prettyName: string;
}

export interface PropertyInfoResult {
  property_type: string;
  count: number;
}
const prettyNames: Record<string, string> = {
  elevatorAccess: "Elevator Access",
  swimmingPool: "Swimming Pool",
  childrenPlayArea: "Children Play Area",
  sportsAreas: "Sports Areas",
  communityRoom: "Community Room",
  gym: "Gym",
  garden: "Garden",
  carPark: "Car Parking",
};

export const transformDataWithPrettyNames = (
  data: PropertyInfo[],
): TransformedPropertyInfo[] => {
  return data.map((item) => ({
    ...item,
    prettyName: prettyNames[item.description] || item.description,
  }));
};
const reversePrettyNames: Record<string, string> = Object.fromEntries(
  Object.entries(prettyNames).map(([key, value]) => [value, key]),
);
export const convertPrettyNamesToPropertyInfo = (
  names: Record<string, number>,
): PropertyInfoResult[] => {
  return Object.entries(names).map(([prettyName, count]) => ({
    property_type: reversePrettyNames[prettyName] || prettyName,
    count,
  }));
};

export const toCamelCase = (str: string): string => {
  return str
    .toLowerCase() // Convert the whole string to lowercase
    .split(/[\s_]+/) // Split by spaces or underscores
    .map(
      (word, index) =>
        index === 0
          ? word // Keep the first word lowercase
          : word.charAt(0).toUpperCase() + word.slice(1), // Capitalize the first letter of subsequent words
    )
    .join(""); // Join them together into one string
};

export const getUserToken = async (email: string, userType: string) => {
  try {
    const response = await instance.get(`/user/getEmail`, {
      params: { email, user_type: userType },
    });
    return response.data.data.token; // Adjust this if the token structure differs
  } catch (error) {
    console.error("Error fetching user token:", error);
    throw error; // Throw error for further handling
  }
};

export const getAmenityTypes = async () => {
  try {
    const response = await instance.get(`/property/amenity/getAmenityTypes`);
    return response.data.data.amenityTypes; // Adjust if the structure differs
  } catch (error) {
    console.error("Error fetching amenity types:", error);
    throw error; // Throw error for further handling
  }
};

export const getAmenities = async (id: string) => {
  try {
    const response = await instance.get(`property/getAmenities/${id}`);
    return response.data; // Adjust if the structure differs
  } catch (error) {
    console.error("Error fetching amenity types:", error);
    throw error; // Throw error for further handling
  }
};
export const updatedAmenities = async (id: string) => {
  try {
    const response = await instance.get(
      `property/editSubPropertyMetrics/${id}`,
    );
    return response.data; // Adjust if the structure differs
  } catch (error) {
    console.error("Error fetching amenity types:", error);
    throw error; // Throw error for further handling
  }
};

// Function to aggregate and transform sub-properties
interface SubProperty {
  description: string;
  count: number;
}

interface TransformedSubProperty {
  id: number;
  type: string;
  quantity: number;
  error: boolean;
}

// Function to aggregate and transform sub-properties
export const transformSubProperties = (
  subPropertiesData: SubProperty[],
): TransformedSubProperty[] => {
  // Step 1: Aggregate quantities by type
  const aggregatedData: Record<string, number> = subPropertiesData.reduce(
    (acc, item) => {
      const { description, count } = item;

      if (acc[description]) {
        acc[description] += count; // Sum the quantity for repeated types
      } else {
        acc[description] = count; // Initialize with the first count if type is new
      }

      return acc;
    },
    {} as Record<string, number>,
  );

  // Step 2: Transform aggregated data into the desired array format
  return Object.entries(aggregatedData).map(([type, quantity], index) => ({
    id: index,
    type: camelCaseToNormal(type),
    quantity: quantity,
    error: false,
  }));
};

export const camelCaseToNormal = (text: string): string => {
  // Add a space before each uppercase letter, then capitalize the first letter of each word
  return text
    .replace(/([A-Z])/g, " $1") // Insert a space before each uppercase letter
    .replace(/^./, (str) => str.toUpperCase()); // Capitalize the first letter of the result
};
