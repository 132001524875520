import { createSlice } from "@reduxjs/toolkit";

const homeSlice = createSlice({
  name: "todos",
  initialState: [],
  reducers: {
    homeFetch(state, action) {
      //   state.push({
      //     id: action.payload.id,
      //     text: action.payload.text,
      //     completed: false,
      //   })
    },
  },
});

export const { homeFetch } = homeSlice.actions;
export default homeSlice.reducer;
