import React, { useContext, useEffect, useState } from "react";
import logoImage from "./../../assets/img/logo.png";
import "./../verify/verify.scss";
import { Trans } from "react-i18next";
import {
  Container,
  CssBaseline,
  Box,
  Typography,
  Grid,
  CircularProgress,
} from "@mui/material";
import { Link, useNavigate, useSearchParams } from "react-router-dom";
import Animation from "../../components/Animation/animation";
import TextTitle from "../../components/title/textTitle";
import instance from "../../utils/axios";
import AppContext from "../../context/app-context";
import { AxiosError } from "axios";
import AlertMessage from "../../components/Aleart/AlertMEssage";
const AccountVerify = () => {
  const navigate = useNavigate();
  let [searchParams, setSearchParams] = useSearchParams();
  const [isLoading, setIsLoading] = useState(true);
  const [isVerified, setIsVerified] = useState<boolean | null>(null);
  const [alertOpen, setAlertOpen] = useState(false);
  const [alertType, setAlertType] = useState<"success" | "error">("success");
  const [errorMessage, setErrorMessage] = useState<string>("");
  const [successMessage, setSuccessMessage] = useState<string>("");
  // const {setToken, setRefreshToken} = useContext(AppContext)

  useEffect(() => {
    const token = searchParams.get("id_token");
    if (token) {
      instance
        .get("/user/checkAppleIDExists", {
          params: {
            apple_id: token,
            user_type: "admin",
            signup_type: "apple",
          },
        })
        .then(async (response) => {
          console.log("checkAppleIDExists response:", response.data);
          if (response.status === 200 && response?.data.userToken) {
            try {
              console.log("Attempting login with apple_id:", token);

              // Attempt the login request
              const loginResponse = await instance.post("/user/apple/login", {
                apple_id: token,
                userType: "admin",
              });

              // Log the response to see if it reaches this line
              console.log("loginResponse:", loginResponse);

              if (loginResponse.status === 200) {
                setIsVerified(true);
                setSuccessMessage("Login successful!");
                setAlertType("success");
                setAlertOpen(true);
                setTimeout(() => {
                  navigate("/admin/admin-profile-creation");
                }, 1500);
              } else {
                console.warn(
                  "Unexpected status code in loginResponse:",
                  loginResponse.status,
                );
                setIsVerified(false);
                setErrorMessage(
                  "Unexpected error during login. Redirecting to signup.",
                );
                setAlertType("error");
                setAlertOpen(true);
                setTimeout(() => {
                  navigate("/signup");
                }, 2500);
              }
            } catch (error: any) {
              console.error("Error during login attempt:", error);

              // Additional logging to inspect error details
              if (error instanceof AxiosError) {
                console.error("Axios error details:", error.response?.data);
              }

              setIsVerified(false);
              setErrorMessage("Login failed.");
              setAlertType("error");
              setAlertOpen(true);
              setTimeout(() => {
                navigate("/signup");
              }, 2500);
            }
          } else {
            console.warn(
              "User token not found in checkAppleIDExists response, redirecting to signup",
            );
            setIsVerified(false);
            setErrorMessage("User does not exist.");
            setAlertType("error");
            setAlertOpen(true);
            navigate("/signup");
          }
        })
        .catch((error) => {
          console.error("Error in checkAppleIDExists API call:", error);

          // Log detailed error information if available
          if (error instanceof AxiosError) {
            console.error("Axios error response data:", error.response?.data);
          }

          setIsVerified(false);
          setErrorMessage("Error checking Apple ID. Redirecting to signup.");
          setAlertType("error");
          setAlertOpen(true);
        })
        .finally(() => {
          setIsLoading(false);
        });
    } else {
      setIsVerified(false);
      setIsLoading(false);
    }
  }, [searchParams, navigate]);

  const handleCloseAlert = () => {
    setAlertOpen(false);
  };

  // if (isLoading) {
  //   return (
  //     <Grid container justifyContent="center" alignItems="center" style={{ height: '100vh' }}>
  //       <CircularProgress />
  //     </Grid>
  //   );
  // }
  return (
    <div className="login">
      <Grid container component={"main"} className="login-container">
        <CssBaseline />
        <Animation />
        <Grid
          item
          xs={12}
          sm={5}
          md={5}
          component={Container}
          maxWidth="xs"
          className="right-side"
        >
          <Box>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignContent: "center",
                textAlign: "center",
              }}
            >
              <div>
                <img
                  src={logoImage}
                  alt="Logo"
                  style={{
                    objectFit: "contain",
                    width: "130px",
                    height: "auto",
                  }}
                />
              </div>
              {isVerified ? (
                <>
                  <Typography
                    sx={{
                      fontSize: "24px",
                      fontWeight: "bold",
                      fontFamily: '"Inter", sans-serif',
                      color: "#000000",
                      marginBottom: "3.5rem",
                    }}
                  >
                    <Trans i18nKey="thankYou">Thank You</Trans>
                  </Typography>
                  <TextTitle
                    className="sub-title"
                    mt={1}
                    text="Your account has been activated."
                  />
                </>
              ) : (
                <>
                  <Typography
                    sx={{
                      fontSize: "24px",
                      fontWeight: "bold",
                      fontFamily: '"Inter", sans-serif',
                      color: "",
                      marginBottom: "3.5rem",
                    }}
                  >
                    <Trans i18nKey="verification">Verification</Trans>
                  </Typography>
                  <TextTitle
                    className="sub-title"
                    mt={1}
                    text="Account Verification..."
                  />
                </>
              )}
            </div>
          </Box>
        </Grid>
      </Grid>
      <AlertMessage
        open={alertOpen}
        onClose={handleCloseAlert}
        severity={alertType}
        message={alertType === "success" ? successMessage : errorMessage}
      />
    </div>
  );
};

export default AccountVerify;
