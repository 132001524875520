export function helper() {}

export function saveRefreshToken(refreshToken: string) {
  document.cookie = `refreshToken=${refreshToken}; path=/; secure; HttpOnly; SameSite=Strict`;
}

// Get Refresh Token from cookies
export function getRefreshToken() {
  const match = document.cookie.match(/(?:^| )refreshToken=([^;]+)(?=\s|$)/);
  return match ? match[1] : null;
}

export function saveToken(token: string) {
  document.cookie = `token=${token}; path=/; secure; HttpOnly; SameSite=Strict`;
}

// Get Token from cookies
export function getToken() {
  const match = document.cookie.match(/(?:^| )token=([^;]+)(?=\s|$)/);
  return match ? match[1] : null;
}

export function validateField(
  fieldName: string,
  value: string,
  password: string,
): string {
  switch (fieldName) {
    case "firstName":
      if (!value) return "First name is required.";
      break;
    case "lastName":
      if (!value) return "Last name is required.";
      break;
    case "email":
      if (!value) return "Email is required.";
      const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
      if (!emailRegex.test(value)) return "Invalid email address.";
      break;
    case "password":
      if (!value) return "Password is required.";
      if (value.length < 8) return "Password must be at least 8 characters.";
      if (!/[0-9]/.test(value)) return "Password must contain a number.";
      if (!/[!@#$%^&*(),.?":{}|<>]/.test(value))
        return "Password must contain a special character.";
      break;
    case "confirmPassword":
      if (value !== password) return "Passwords must match.";
      break;
    default:
      break;
  }
  return "";
}

export function setValuesToSession(name: string, value: string) {
  sessionStorage.setItem(name, value);
}

export function getValuesFromSession(name: string) {
  sessionStorage.getItem(name);
}

export function setValuesToCookies(name: string, value: string) {
  let expirationDate = new Date();
  expirationDate.setDate(expirationDate.getDate() + 1);
  document.cookie =
    `${name}=${value}; expires=` +
    expirationDate.toUTCString() +
    "; path=/; Secure; HttpOnly";
}

export function getValuesFromCookies(name: string) {
  sessionStorage.getItem(name);
}
