import React, { useEffect, useState } from "react";
import {
  Box,
  Typography,
  FormControl,
  MenuItem,
  Select,
  TextField,
  Chip,
  OutlinedInput,
  SelectChangeEvent,
  FormHelperText,
} from "@mui/material";
import WithLandPageView from "../../hoc/hoc-admin";
import DoubleArrowIcon from "./components/customIcons/DoubleArrowIcon";
import QuantityAdjuster from "./components/QuantityAdjuster";
import CardMain from "./components/CardMain";
import CancelSubmitButtons from "./components/CancelSubmitButtons";
import SingleFormControl from "./components/SingleFormControl";
import instance from "../../utils/axios";
import {
  TransformedPropertyInfo,
  transformDataWithPrettyNames,
} from "./services/dataMap";
import SelectAmenities from "./components/SelectAmenities";
import { useDispatch } from "react-redux";
import { setLabel } from "../../store/labels/labels";
import { Trans, useTranslation } from "react-i18next";

interface Option {
  key: string;
  value: string;
}

const AddingAminityInformation: React.FC = () => {
  const { t }: { t: (key: string) => string } = useTranslation();
  const dispatch = useDispatch();
  // const actionType = 'LABEL_CHANGE';

  useEffect(() => {
    dispatch(
      setLabel({
        title: t("addNewSubProperty"),
        subTitle: t("addNewPropertyToPortfolio"),
      } as any),
    );
  }, []);
  const [amenities, setAmenities] = useState<TransformedPropertyInfo[]>([]);
  useEffect(() => {
    const getAmenityTypes = async () => {
      try {
        const tokenResponse = await instance.get("user/getEmail", {
          params: {
            email: "methmaaravinda@gmail.com",
            user_type: "admin",
          },
        });
        const getAmenityTypesResponse = await instance.get(
          "property/amenity/getAmenityTypes",
          {
            headers: {
              Authorization: `Bearer ${tokenResponse.data.data.token}`,
            },
          },
        );
        setAmenities(
          transformDataWithPrettyNames(
            getAmenityTypesResponse.data.data.amenityTypes,
          ),
        );
      } catch (error) {
        console.log(error);
      }
    };
    getAmenityTypes();
  }, []);

  const subPropertyOptions = [
    { key: "a", value: "a" },
    { key: "b", value: "b" },
    { key: "c", value: "c" },
  ];
  const [subPropertyType, setSubPropertyType] = useState<string>("");
  const [propertyName, setPropertyName] = useState<string>("");
  const [selectedAmenities, setSelectedAmenities] = useState<string[]>([]);
  const [amenityDetails, setAmenityDetails] = useState<{
    [key: string]: number;
  }>({});
  const [error, seterror] = useState({
    subPropertyTypeError: false,
    subPropertyNameError: false,
    selectAmenityError: false,
  });
  const handleTypeChange = (event: SelectChangeEvent) => {
    seterror((prev) => ({ ...prev, subPropertyTypeError: false }));
    setSubPropertyType(event.target.value as string);
  };

  const handleNameChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    seterror((prev) => ({ ...prev, subPropertyNameError: false }));
    setPropertyName(event.target.value as string);
  };

  const handleAmenityChange = (event: SelectChangeEvent<string[]>) => {
    seterror((prev) => ({ ...prev, selectAmenityError: false }));
    const value = event.target.value as string[];

    // Update selected amenities and remove deselected ones from amenityDetails
    setSelectedAmenities(value);

    // Create a new amenityDetails object with only selected amenities
    const updatedAmenities = value.reduce(
      (acc, amenity) => {
        acc[amenity] = amenityDetails[amenity] || 1; // Initialize with 1 if new, keep current value if existing
        return acc;
      },
      {} as { [key: string]: number },
    );

    setAmenityDetails(updatedAmenities);
  };

  const handleQuantityChange = (amenity: string, increment: boolean) => {
    setAmenityDetails((prev) => ({
      ...prev,
      [amenity]: Math.max(1, (prev[amenity] || 1) + (increment ? 1 : -1)),
    }));
  };
  const handleDeleteIconClick = (key: string, event: React.MouseEvent) => {
    setSelectedAmenities((prev) => prev.filter((amenity) => amenity !== key)); // Remove the selected chip
    setAmenityDetails((prevDetails) => {
      const updatedDetails = { ...prevDetails };
      delete updatedDetails[key];
      return updatedDetails;
    });
  };
  const handleSubmit = () => {
    if (propertyName == "") {
      console.log("hiii");
      console.log(error);
      seterror((prev) => ({ ...prev, subPropertyNameError: true }));
      console.log(error);
    }
    if (subPropertyType === "") {
      console.log("object");
      seterror((prev) => ({ ...prev, subPropertyTypeError: true }));
    }
    if (selectedAmenities.length < 1) {
      seterror((prev) => ({ ...prev, selectAmenityError: true }));
    } else {
      console.log("Form Data:", {
        subPropertyType,
        propertyName,
        selectedAmenities,
        amenityDetails,
      });
    }
  };

  return (
    <Box sx={{ marginRight: "37px", padding: "0px" }}>
      <CardMain
        header={t("subPropertyInformation")}
        subHeader={t("subPropertyType")}
        cardContent=<Box sx={{ display: "flex", gap: 2 }}>
          <SingleFormControl
            value={subPropertyType}
            onChange={handleTypeChange}
            error={error.subPropertyTypeError}
            helperText={t("SelectAnOption")}
            marginTop="-17px"
          >
            <MenuItem disabled value="" sx={{ fontSize: "14px" }}>
              <span style={{ color: "#6b6b6b" }}>
                <Trans i18nKey="selectSubPropertyType">
                  Select a Sub Property Type
                </Trans>
              </span>
            </MenuItem>
            {subPropertyOptions.map((option) => (
              <MenuItem
                key={option.key}
                value={option.key}
                sx={{ fontSize: "14px" }}
              >
                {option.value}
              </MenuItem>
            ))}
          </SingleFormControl>

          <TextField
            helperText={t("addPropertyName")}
            // error={error.subPropertyNameError}
            size="small"
            sx={{
              position: "relative",
              "& .MuiInputBase-input": {
                padding: "10px 10px", // Top/bottom padding of 12px, left/right padding of 14px
                fontSize: "14px", // Font size for the input text
                color: "#333333",
              },
              borderRadius: "10px",
              "& .MuiOutlinedInput-notchedOutline": {
                borderRadius: "10px", // Ensures outline is curved
              },
              "& .MuiInputLabel-root": {
                fontSize: "14px", // Font size for the label text
                color: "#888888", // Color for the label text
              },
              "& .MuiInputLabel-root.Mui-focused": {
                color: "#D5D5D5", // Color for the label text when focused
              },
              "& .MuiOutlinedInput-root": {
                "& fieldset": {
                  borderColor: "#D5D5D5", // Border color of the TextField
                },
                "&:hover fieldset": {
                  borderColor: "#D5D5D5", // Border color on hover
                },
                "&.Mui-focused fieldset": {
                  borderColor: "#D5D5D5", // Border color when focused
                },
              },
            }}
            InputProps={{
              style: {
                fontSize: "14px", // Placeholder font size
                color: "#888888", // Placeholder font color
              },
            }}
            FormHelperTextProps={{
              sx: {
                color: "red",
                margin: "3px 14px",
                lineHeight: "0.66",
                visibility: error.subPropertyNameError ? "visible" : "hidden",
              }, // Ensure no extra spacing
            }}
            label={t("propertyName")}
            value={propertyName}
            onChange={handleNameChange}
            variant="outlined"
            fullWidth
          />
        </Box>
      />

      <CardMain
        header={t("amenityInformation")}
        subHeader={t("selectAmenitys")}
        cardContent={
          <SelectAmenities
            amenities={amenities}
            amenityDetails={amenityDetails}
            error={error.selectAmenityError}
            onAmenityChange={handleAmenityChange}
            onDeleteAmenity={(key, event) => {
              setSelectedAmenities((prev) =>
                prev.filter((item) => item !== key),
              );
              setAmenityDetails((prev) => {
                const updated = { ...prev };
                delete updated[key];
                return updated;
              });
            }}
            onQuantityChange={handleQuantityChange}
          />
        }
      />

      <CancelSubmitButtons
        handleCancel={() => {}}
        handleSubmit={handleSubmit}
      />
    </Box>
  );
};
export default WithLandPageView(AddingAminityInformation, {
  title: "Add Parent Property",
  subTitle:
    "Add a property to your portfolio and start managing it efficiently.",
});
